import React from 'react'
import './styling/TopTips.css';

export default function CarFinder() {

    return<div className="article">
    <h1>5 Used Car Buying Tips</h1>

    <div className="tip">
      <h2>1. Tyres</h2>
      <p>The quality of a car's tyres often reflects the level of maintenance it received throughout its life. When a car is equipped with premium tyres, it usually indicates that it has been well taken care of in all aspects. Conversely, cars with budget tyres may suggest a lower standard of maintenance. Premium tyre brands include Pirelli, Michelin, Continental, Goodyear, and Bridgestone. For mid-level tyres, one can consider options like Hankook, Firestone, and Dunlop.</p>
    </div>

    <div className="tip">
      <h2>2. Mileage</h2>
      <p>The nature of the miles driven can be significant. For example, if an 8-year-old car has covered 30,000 miles, it averages around 3,750 miles per year, indicating what we refer to as "hard miles." These hard miles typically involve driving when the engine is cold, leading to increased wear, and at lower speeds, requiring frequent gear changes that can wear out the gearbox.</p> 
        
        <p>On the other hand, if a 3-year-old car has accumulated 50,000 miles, averaging just over 16,000 miles per year, it suggests "easy miles." Easy miles are driven with a warm engine, resulting in minimal wear, and require fewer gear changes, reducing strain on the gearbox. This can potentially help you find a great deal.</p>
    </div>

    <div className="tip">
      <h2>3. Previous Owners</h2>
      <p>Cars that have only had one owner from the beginning are usually cherished. On the flip side, when cars change owners often, they often end up missing important services and maintenance. In some extreme cases, they're put up for sale because of some sneaky underlying issue. So, at the very least, it's worth checking if the most recent owner has held onto the car for at least a year. Also if you spot a car for sale with the mileage still rising, it's a good sign that the current owner still has faith in their car.</p>
    </div>

    <div className="tip">
      <h2>4. Service History</h2>
      <p>A car without a full service history is a red flag. Regular servicing is crucial for reliability, with at least one annual service expected. Neglected servicing often leads to costly repairs. Prioritise vehicles with a solid service record for hassle-free motoring.</p>
    </div>

    <div className="tip">
      <h2>5. MOT History</h2>
      <p>Use <a href="https://www.gov.uk/check-mot-history" target="_blank" rel="noopener noreferrer">https://www.gov.uk/check-mot-history</a> to review the MOT history of any car you plan to purchase. Avoid vehicles with many unresolved advisories, as they can potentially evolve into MOT failures that the next owner will need to address.</p>
    </div>
    
    <p>Make sure to use the <a href="/">car information checker</a> for additional important details.</p>
    </div>
}