import React from 'react';
import './styling/article.css';

export default function MotRust() {
    return (
        <div className="article">
            <h1>Understanding Corrosion in MOT History: What You Should Know</h1>

            <div className="tip">
                <h2>Replaceable Parts:</h2>
                <p>Corrosion on components like suspension or exhaust systems isn't ideal, but it's manageable. These parts can usually be swapped out without breaking the bank. While it's a sign to keep an eye on, it's often not a deal-breaker.</p>
            </div>

            <div className="tip">
                <h2>Critical Areas:</h2>
                <p>The real trouble starts when corrosion hits critical spots like the body or frame. Fixing corrosion here is a whole different ball game. It involves complex repairs, often requiring welding skills. Plus, it can get pricey, fast.</p>
            </div>

            <div className="tip">
                <h2>Safety and Legal Concerns:</h2>
                <p>Corrosion isn't just a cosmetic issue. In critical areas, it compromises the vehicle's safety, making it less able to protect you in a crash. It can even make the vehicle unroadworthy, leading to legal trouble if ignored.</p>
            </div>

            <div className="tip">
                <h2>Early Detection is Key:</h2>
                <p>Spotting corrosion early is crucial. Regular checks in vulnerable areas like wheel arches and sills can catch it before it gets worse. Cleaning and applying rust inhibitors can help slow down the process too.</p>
            </div>

            <div className="tip">
                <h2>Used Car Shopping:</h2>
                <p>When buying a used car, scrutinising its MOT history for corrosion is a must. Minor rust spots might not be a big deal, but widespread corrosion, especially in crucial areas, should make you think twice.</p>
            </div>

            <h2>Summary</h2>
            <p>In short, not all corrosion is created equal. While some cases are manageable, severe corrosion in critical areas can be a real headache. Stay vigilant, and you'll steer clear of rusty surprises down the road.</p>
        </div>
    );
}