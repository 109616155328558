import React, {useEffect, useState} from 'react';
import { useSearchParams } from "react-router-dom";
import { Button, ListGroup } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import './styling/FullCheckv2.css';

export default function FullCheckv2() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState('true')
    const [notFound, setNotFound] = useState(false);

    useEffect(()=> {
        const fetchData = async () => {
            const response = await fetch(
                process.env.REACT_APP_BASE_URL + "/Vehicle?registration=" + searchParams.get("reg"), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            );
            
            if (!response.ok){
                setNotFound(true)
            }
            
            if (response.ok){
                setData(await response.json())
                console.log(data);
            } else{
               
            }
            setLoading(false);
            setSearchParams(searchParams);
        }

    fetchData()
    // make sure to catch any error
    .catch(console.error);

    },[])

    const extractDayFromDateStr = (dateStr) => {
        // Splitting the string based on the space separator
        const dateParts = dateStr.split(' ');
        const date = dateParts[0]; // Extracting the date part
    
        // Splitting the date part based on the dot separator
        const dateComponents = date.split('.');
        const year = dateComponents[0];
        const month = dateComponents[1];
        const day = dateComponents[2];
    
        // Converting month number to month name
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = months[parseInt(month) - 1];
    
        // Adding suffix to the day
        const dayWithSuffix = addOrdinalSuffix(parseInt(day));
    
        return `${dayWithSuffix} ${monthName} ${year}`;
    };
    
    // Function to add suffix to the day
    const addOrdinalSuffix = (day) => {
        if (day === 1 || day === 21 || day === 31) {
            return day + "st";
        } else if (day === 2 || day === 22) {
            return day + "nd";
        } else if (day === 3 || day === 23) {
            return day + "rd";
        } else {
            return day + "th";
        }
    };

    const parseBool = (status) => (status ? 'YES' : 'NO');
    const parseWriteOff = (status) => (status === null ? 'N/A' : status);
    const parseFinance = (status) => (status === 0 ? 'NO' : 'YES');

    const parseTitleAndVal = (title, value) =>{

        if (value === null || value === undefined) {
            return null;
        }
        return (
            <div>
                <h7 class="greytext">{title}</h7>
                <h4>{value}</h4>
            </div>
        )
    }
 
    if(loading){
        return <div className='loading'>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div> 
    }

    if (notFound) {
        return <div>
                    <h1>Hmmm, we can't find your car</h1>
                    <h3>Please email info@carinformation.co.uk or get in touch on instagram @carinformation for an immediate resolution</h3>
                </div>
        
    }

    return (
        <div class="items">
            <div>
                <h1 className="plate">{data.vrm}</h1>
                <h1>{data.make} {data.model}</h1>
                <hr className="line"></hr>
                <h7 class="greytext">Date of first registration</h7>
                <h4>{data.dateFirstRegistered}</h4>
                <h7 class="greytext">Last V5 issued date</h7>
                <h4>{data.latestV5cIssuedDate}</h4>
                <h7 class="greytext">Last keeper change date</h7>
                <h4>{data.latestKeeperChangeDate}</h4>
                <h7 class="greytext">Previous Keepers</h7>
                <h4>{data.previousKeeperCount}</h4>
                <hr></hr>
                {parseTitleAndVal("Written Off", parseBool(data.writtenOff))}
                {parseTitleAndVal("Write Off Count", data.writeOffRecordCount)}
                {parseTitleAndVal("Write Off Category", data.writeOffCategory)}
                {parseTitleAndVal("Write Off Date", data.writeOffDate)}
                {parseTitleAndVal("Stolen", parseBool(data.stolen))}
                {parseTitleAndVal("Stolen Date", data.stolenDate)}
                {parseTitleAndVal("Scrapped", parseBool(data.scrapped))}
                {parseTitleAndVal("Imported", parseBool(data.imported))}
                {parseTitleAndVal("Import Date", data.importDate)}
                <hr></hr>
                {parseTitleAndVal("Outstanding Finance", parseFinance(data.financeRecordCount))}
                {parseTitleAndVal("Number Of Outstanding Finance Records", data.financeRecordCount)}

                {data.financeRecordList.length > 0 && (
                    <div>
                        {data.financeRecordList.map (record => ( record && (
                    <div>
                        {parseTitleAndVal("Agreement Date", record.agreementDate.split("T")[0])}
                        {parseTitleAndVal("Agreement Type", record.agreementType)}
                        {parseTitleAndVal("Agreement Term", record.agreementTerm + " MONTHS")}
                        {parseTitleAndVal("Finance Company", record.financeCompany)}
                    </div>)))}
                    </div>
                )}
                <hr></hr>
                
                {data.plateChangeList !== null && (
                    <div>
                        {data.plateChangeList.map (plateChange => ( plateChange && (
                    <div>
                        {parseTitleAndVal("Previous Registration", plateChange.previousVrm)}
                        {parseTitleAndVal("Date Changed", plateChange.dateChanged)}
                    </div>)))}
                    <hr></hr>
                    </div>
                )}
                {parseTitleAndVal("Last 5 Digits of VIN", data.vinLast5)}
                {parseTitleAndVal("Engine Size", data.engineCapacity)}
                {parseTitleAndVal("Transmission", data.transmissionType)}
                {parseTitleAndVal("Colour", data.colour)}
                {parseTitleAndVal("Fuel Type", data.fuelType)}
               
            </div>
        </div>
        // <div class="main">
        //     <ListGroup>               
        //             <ListGroup.Item id="plate">{data.vrm}</ListGroup.Item>
        //             <h1></h1>
        //         <div class="list">
        //             <ListGroup.Item><b>Make</b> {data.make} {data.model}</ListGroup.Item>
        //             <ListGroup.Item><b>Last V5</b> {data.latestV5cIssuedDate}</ListGroup.Item>
        //             <ListGroup.Item><b>Month of First Registration</b> {data.dateFirstRegistered}</ListGroup.Item>
        //             <ListGroup.Item><b>Mileage Anomaly</b> {parseBool(data.mileageAnomalyDetected)}</ListGroup.Item>
        //             <ListGroup.Item><b>Previous Keepers</b> {data.previousKeeperCount}</ListGroup.Item>
        //             <ListGroup.Item><b>Last Keeper Change Date</b> {data.latestKeeperChangeDate}</ListGroup.Item>
        //             <ListGroup.Item><b>Write Off Count</b> {data.writeOffRecordCount}</ListGroup.Item>
        //             <ListGroup.Item><b>Write Off Category</b> {parseWriteOff(data.writeOffCategory)}</ListGroup.Item>
        //             <ListGroup.Item><b>Outstanding Finance</b> {parseFinance(data.financeRecordCount)}</ListGroup.Item>
        //             <ListGroup.Item><b>Imported</b> {parseBool(data.imported)}</ListGroup.Item>
        //             <ListGroup.Item><b>Stolen</b> {parseBool(data.stolen)}</ListGroup.Item>
        //             <ListGroup.Item><b>Fuel Type</b> {data.fuelType}</ListGroup.Item>
        //             <ListGroup.Item><b>Engine Capacity</b> {data.engineCapacity}cc</ListGroup.Item>
        //             {data.mileageRecordList && data.mileageRecordList.length > 0 &&
        //                 <ListGroup.Item><b>Mileage History</b>
        //                     {data.mileageRecordList.map((mileageRecord, index) => (
        //                         <ListGroup.Item key={index}>{mileageRecord.dateOfInformation} - {mileageRecord.mileage}</ListGroup.Item>
        //                     ))}
        //                 </ListGroup.Item>
        //             }
        //         </div>
        //     </ListGroup>
        // </div>
    )
}