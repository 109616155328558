import React from 'react';
import './styling/article.css';

export default function UsedCarAdvisories() {
    return (
        <div className="article">
            <h1>Navigating Outstanding Advisories When Buying a Used Car</h1>

            <p>When perusing the MOT history of a used car, encountering outstanding advisories can raise a flag of caution. While it's tempting to assume that a vehicle with unresolved advisories hasn't been properly maintained, the reality is often more nuanced.</p>

            <h2>Understanding Advisories:</h2>
            <p>Advisories are issued during an MOT test when an issue is noted that doesn't necessarily warrant an immediate fail but should be addressed in the near future to ensure the vehicle's safety and roadworthiness. Common advisory items include worn tires, brake pads nearing the end of their lifespan, or minor fluid leaks.</p>

            <h2>Not Always a Red Flag:</h2>
            <p>While it's essential to take advisories seriously, especially if they pertain to critical components like brakes or suspension, not all advisories are created equal. For instance, issues like worn tires, while needing attention, might not be indicative of neglect but rather normal wear and tear.</p>

            <h2>Assessing Severity:</h2>
            <p>When considering a used car with outstanding advisories, it's crucial to assess the severity of each advisory. Some advisories may be minor and easily rectifiable, while others could signal more significant underlying issues that require immediate attention. Understanding the nature of each advisory can provide valuable insights into the overall condition of the vehicle.</p>

            <h2>Owner Care and Maintenance:</h2>
            <p>It's also worth considering the maintenance habits of the previous owner. A car that has been meticulously cared for is more likely to have had advisories promptly addressed. Conversely, a vehicle with numerous outstanding advisories might suggest a lack of attention to maintenance, but this isn't always the case.</p>

            <h2>Proceed with Caution:</h2>
            <p>When dealing with a used car with outstanding advisories, thorough inspection and assessment are key. Consider having a trusted mechanic inspect the vehicle to provide an expert opinion on the severity of the advisories and their potential impact on the vehicle's safety and reliability.</p>

            <h2>Conclusion:</h2>
            <p>In summary, outstanding advisories in a used car's MOT history shouldn't necessarily be a deal-breaker, but they do warrant careful consideration. Assessing the severity of each advisory and understanding the vehicle's maintenance history can help you make an informed decision and avoid any unpleasant surprises down the road.</p>
        </div>
    );
}