import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom";
import { Button, Form, ListGroup } from 'react-bootstrap'
import { Checkmark } from '../copied/checkmark' 
import Spinner from 'react-bootstrap/Spinner';
import './styling/FullMotCheck.css'

export default function FullMotCheck() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [motData, setMotData] = useState(null);
    const [showMOT, setShowMOT] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    let reg = searchParams.get("reg");
    
    useEffect(()=> {
        const getCarDetails = async () => {
            let response = await fetch(
                process.env.REACT_APP_BASE_URL + '/Vehicle/dvlamot?registration=' + reg, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            );
    
            if (response.ok){
                var motResponse = await response.json()
                setMotData(motResponse)
            } else{
                navigate(`/?carnotfound=true`)
            }

        }

        getCarDetails()
    }, [])

    const extractDayFromDateStr = (dateStr) => {
        // Splitting the string based on the space separator
        const dateParts = dateStr.split(' ');
        const date = dateParts[0]; // Extracting the date part
    
        // Splitting the date part based on the dot separator
        const dateComponents = date.split('.');
        const year = dateComponents[0];
        const month = dateComponents[1];
        const day = dateComponents[2];
    
        // Converting month number to month name
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = months[parseInt(month) - 1];
    
        // Adding suffix to the day
        const dayWithSuffix = addOrdinalSuffix(parseInt(day));
    
        return `${dayWithSuffix} ${monthName} ${year}`;
    };
    
    // Function to add suffix to the day
    const addOrdinalSuffix = (day) => {
        if (day === 1 || day === 21 || day === 31) {
            return day + "st";
        } else if (day === 2 || day === 22) {
            return day + "nd";
        } else if (day === 3 || day === 23) {
            return day + "rd";
        } else {
            return day + "th";
        }
    };
    
    const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate();
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const month = monthNames[d.getMonth()];
        const year = d.getFullYear();
      
        const dayWithSuffix = addOrdinalSuffix(day);
      
        return `${dayWithSuffix} ${month} ${year}`;
    };  

    const goToCheckout = async () => {

        let response = await fetch(
            process.env.REACT_APP_BASE_URL + '/Checkout?registration=' + reg, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        );

        var responseData = await response.json();

        console.log("Making call");
        console.log("Your api - " + responseData.url);

        window.location.href = responseData.url;
        // setLoading(false)
    }

    const parseMot = (status, daysLeft) => {
        if (daysLeft < 0) {
            return <h1 className='red-text'>EXPIRED</h1>;
        } else if (status !== "PASSED") {
            return <h1 className='red-text'>FAILED</h1>;
        } else {
            return <h1 style={{ color: 'green' }}>PASSED</h1>
        }
    };

    const parseTestresult = (result, completedDate) => {
        if (result === "FAILED"){
            return <h5><b><span style={{ color: 'red' }}>{result}</span></b> - {extractDayFromDateStr(completedDate)}</h5>
        }
        return <h5><b><span style={{ color: 'green' }}>{result}</span></b> - {extractDayFromDateStr(completedDate)}</h5>
    }

    if(motData === null || loading){
        return <div className='loading'>
            <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    }

return <div className='items'>
        <div className='wrapper'>
            <p className="numberplate">{reg}</p>
            <h1 id="item">{motData.make} {motData.model}</h1>
            <hr></hr>
            {motData.firstUsedDate && (
            <>
                <h6 className="greytext">Date First Used</h6>
                <h4><b>{extractDayFromDateStr(motData.firstUsedDate)}</b></h4>
            </>
            )}
            <h6 className="greytext">Colour</h6>
            <h4><b>{motData.primaryColour}</b></h4>
            <h6 className="greytext">Fuel Type</h6>
            <h4><b>{motData.fuelType}</b></h4>
            {motData.motTests && motData.motTests.length > 0 ? (
                <>
                    {motData.motTests[0].daysLeft >= 0 && (
                        <>
                            <h6 className="greytext">Days until next MOT</h6>
                            <h4><b>{motData.motTests[0].daysLeft}</b></h4>
                        </>
                    )}
                    <h6 className="greytext">CarInformation Insights</h6>
                    <div style={{ paddingLeft: '3%' }}>
                        {motData.outstandingAdvisories >= 0 && (
                            <b><p style={{ margin: '5px 0' }}><a href="/advisories">{motData.outstandingAdvisories} Outstanding Advisories</a></p></b>
                        )}
                        {motData.corroded && (
                            <b><p style={{ margin: '5px 0' }}><a href="/motrust">This car has had structural corrosion problems</a></p></b>
                        )}
                        {motData.oilLeak && (
                            <b><p ><a href="/oilleaks">This car has experienced past oil leaks</a></p></b>
                        )}
                    </div>
                </>
            ) : (
                <div>
                    <h6 className="greytext">MOT Valid Until</h6>
                    <h4 style={{ color: 'green' }}><b>{formatDate(motData.motTestExpiryDate)}</b></h4>
                    <br></br>
                </div>
            )}
            
            

            {motData.motTests && motData.motTests.length > 0 && (
                <>
                    <h6 className="greytext">MOT Status</h6>
                    <h4>{parseMot(motData.motTests[0].testResult, motData.motTests[0].daysLeft)}</h4>
                
                    {!showMOT ?
                        <h4><b>Mot History</b><Button variant="link" onClick={() => {setShowMOT(true)}}><b>+show</b></Button></h4> 
                        :
                        <h4><b>Mot History</b><Button variant="link" onClick={() => {setShowMOT(false)}}><b>-hide</b></Button></h4>
                    }
                    {showMOT && (
                        <div>
                            {motData.motTests.map(record => (
                            <ListGroup.Item key={record.testDate}>
                                <hr></hr>
                                {parseTestresult(record.testResult, record.completedDate)}
                                <p>{record.odometerValue} {record.odometerUnit}</p>
                                {record.rfrAndComments.map (comments => (
                                <p>{comments.type} - {comments.text}</p>
                                ))}
                            </ListGroup.Item>
                            ))}
                        </div>
                    )}
                </>
            )}
            <ListGroup.Item><b>Previous Keepers</b> <i>included in full report</i></ListGroup.Item>
                <ListGroup.Item><b>Mileage Anomaly</b> <i>included in full report</i></ListGroup.Item>
                <ListGroup.Item><b>Write Off Status</b> <i>included in full report</i></ListGroup.Item>
                <ListGroup.Item><b>Outstanding Finance</b> <i>included in full report</i></ListGroup.Item>
                <ListGroup.Item><b>Stolen</b> <i>included in full report</i></ListGroup.Item>
                <ListGroup.Item><b>Imported</b> <i>included in full report</i></ListGroup.Item>
                <br></br>
            <Button style={{ width: "100%", maxWidth: "700px" }} id = "drive-button" variant="success" onClick={() => {goToCheckout(); setLoading(true);}}><b>Get Full Report £5.99</b></Button>
            
            <hr></hr>
            <h4><b><a href="https://www.carinformation.co.uk/fullcheck?reg=ef88c427-47f4-4d38-be4e-0c2cdbdd0581">View Sample Report</a></b></h4>
        </div>
</div>
}