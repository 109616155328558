import DvlaForm from './components/DvlaForm';
import './App.css';
import React, { useState, createContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PaymentFailed from "./pages/PaymentFailed";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Home from './pages/Home';
import TopTips from './pages/TopTips';
import OurData from './pages/OurData';
import ReactGA from "react-ga4";
import DvlaMotCheck from './pages/DvlaMotCheck';
import FullCheckv2 from './pages/FullCheckV2';
import MotRust from './pages/articles/motrust';
import UsedCarAdvisories from './pages/articles/advisoires';
import OilLeakBuyersGuide from './pages/articles/oilleak';

const MyContext = createContext();

function App() {
  const [reg, setReg] = useState('DREAM CAR')
  const [dvlaData, setDvlaData] = useState([])

  useEffect(() => {
    ReactGA.initialize('G-DYGYRNKFGC')
  }, [])

  ReactGA._gtag('testanalytic')
  return (
    <div className='Background'>
      <Navbar className='nav' sticky="top" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">
          <img src="/logo.png" width="70%" height="70%" />
          </Navbar.Brand>
        </Container>
      </Navbar>

      <div className="App">
        <BrowserRouter>
          <MyContext.Provider>
            <Routes>
                <Route path="/" element={<Home/>}>
                </Route>
                <Route path="fullmotcheck" element={<DvlaMotCheck/>}>
                </Route>
                <Route path="dvla" element={<DvlaForm reg={reg} setReg={setReg} dvlaData={dvlaData} setDvlaData={setDvlaData} />}>
                </Route>
                <Route path="fullcheck" element={<FullCheckv2/>}>
                </Route>
                <Route path="paymentfailed" element={<PaymentFailed/>}>
                </Route>
                <Route path="toptips" element={<TopTips/>}>
                </Route>
                <Route path="ourdata" element={<OurData/>}>
                </Route>
                <Route path="motrust" element={<MotRust/>}>
                </Route>
                <Route path="advisories" element={<UsedCarAdvisories/>}>
                </Route>
                <Route path="oilleaks" element={<OilLeakBuyersGuide/>}>
                </Route>
              </Routes>
          </MyContext.Provider>
        </BrowserRouter>
      </div>

        <div className='footer'>
          <hr></hr>
          <small>info@carinformation.co.uk</small>
          <br></br>
          <small>Carinformation Ltd | Company no 15160179</small>
        </div>
    </div>
  );
}

export default App;
