import React, {useEffect} from 'react'
import Table from 'react-bootstrap/Table';
import './styling/OurData.css'

export default function OurData() {
    return<div>
        <div className='wrapper'>
            <br></br>
            <p>
            We're dedicated to delivering reliably high-quality data, 
            ensuring that our customers have the trustworthy insights they need to make informed decisions.
            </p>
            <hr></hr>
            <h5>Where Our Data Comes From</h5>
            <br></br>
            <h6>Full Check</h6>
            <Table size="sm" striped bordered hover>
            <thead>
                <tr>
                <th></th>
                <th>Data Source</th>
                <th>Data Owner</th>
                <th>Update frequency</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><b>Exported</b></td>
                    <td>Experian</td>
                    <td>DVLA</td>
                    <td>Weekly</td>
                </tr>
                <tr>
                    <td><b>Finance</b></td>
                    <td>Experian</td>
                    <td>Experian</td>
                    <td>Daily</td>
                </tr>
                <tr>
                    <td><b>Imported</b></td>
                    <td>Experian</td>
                    <td>DVLA</td>
                    <td>Weekly</td>
                </tr>
                <tr>
                    <td><b>Stolen</b></td>
                    <td>Experian</td>
                    <td>Experian</td>
                    <td>Daily</td>
                </tr>
                <tr>
                    <td><b>Write Off</b></td>
                    <td>Experian</td>
                    <td>Experian</td>
                    <td>Daily</td>
                </tr>
                <tr>
                    <td><b>Plate Change</b></td>
                    <td>Experian</td>
                    <td>DVLA</td>
                    <td>Weekly</td>
                </tr>
            </tbody>
            </Table>

            <hr></hr>
            <h6>MOT Data</h6>
            <Table striped bordered hover>
            <thead>
                <tr>
                <th><i></i></th>
                <th>Data Source</th>
                <th>Data Owner</th>
                <th>Update frequency</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><b>MOT Days left</b></td>
                    <td>DVSA</td>
                    <td>MOT</td>
                    <td>Hourly</td>
                </tr>
                <tr>
                    <td><b>MOT Status</b></td>
                    <td>DVSA</td>
                    <td>MOT</td>
                    <td>Hourly</td>
                </tr>
                <tr>
                    <td><b>Tax Status</b></td>
                    <td>DVLA</td>
                    <td>DVLA</td>
                    <td>Daily</td>
                </tr>
                <tr>
                    <td><b>Fuel Type</b></td>
                    <td>Experian</td>
                    <td>DVLA</td>
                    <td>Monthly</td>
                </tr>
            </tbody>
            </Table>
            <br></br>
            <p>If you have any other queries about our data please get in touch at info@carinformation.co.uk</p>
        </div>
    </div>
}